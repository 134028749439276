.logo-container {
  z-index: 0;
  width: 500px;
  height: 709px;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 15;
  left: auto;
  margin: auto;

  svg {
    width: 100%;
    height: auto;
    bottom: 0;
    transform: rotateZ(0deg) !important;
  }

  .solid-logo {
    position: absolute;
    top: auto;
    right: 200;
    bottom: auto;
    left: auto;
    margin: auto;
    width: 150%;
    opacity: 0;
    transform: rotateZ(0deg) !important;
    z-index: 1;
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 1.5;
}
